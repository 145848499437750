import React, { useEffect, useState } from "react";
import Radio from "../components/radio";
import styled from "styled-components";

const RadioContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000000;
  
  @media (max-width: 768px) {
    ${props => props.className !== 'home' ? `
      top: initial;
      bottom: 20px;
      left: 20px;
      right: initial;
    ` : ''}

    > a {
      width: 80px;
      display: block;
    }
  }
  
  .now-playing-container {
    transition: transform 1s;
    transform: translateY(0);
    
    &.playing {
      transform: translateY(-100%);
    }
  }
`;

export default (props) => {
  const { children, location } = props;
  const [section, setSection] = useState('home');

  useEffect(() => {
    const name = location.pathname.replace(/\//gi, '');
    setSection(name === '' ? 'home' : name);
  })
  return (
    <div className={section}>
      <RadioContainer className={section}>
        <Radio />
      </RadioContainer>
      {children}
    </div>
  );
}
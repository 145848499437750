import React from "react"
import styled from "styled-components";
import tracksData from "../content/radio.json";

const Audio = styled.audio`
  visibility: hidden;
  position: absolute;
  top: -2000px;
`;
const Player = React.memo(({playerRef}) => (
    <Audio ref={playerRef} id={'radio'} controls autoPlay={false}>
        <source type="audio/wav"/>
    </Audio>
));
const RadioButton = styled.a`
  cursor: pointer;
  filter: saturate(0);
  transition: filter .3s;
  &:hover {
    filter: saturate(1);
  }
  &.playing {
    filter: saturate(1) !important;
  }
  img {
    margin: 0;
    width: 75px;
  }
`;
const NowPlaying = styled.div`
  position: fixed;
  z-index: 20000000;
  top: 0;
  left: 50%;
  transform: translateX(-50%) ${props => props.playing ? `translateY(0)` : `translateY(-100%)`};
  max-width: 100%;
  font-family: 'DS-DIGI',serif;
  font-size: 14px;
  background: rgba(255, 255, 255, .2);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: transform .8s;
  
  img {
    width: 20px;
    margin: 0 5px 0 0;
  }
  span strong {
    margin-right: 3px;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 10px;
    text-transform: uppercase;
  }
`;

const Prev = styled.button`
    background: transparent;
    width: 0;
    border: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 12px solid #edd307;
    position: absolute;
    left: -30px;
    cursor: pointer;
`;
const Next = styled(Prev)`
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 12px solid #edd307;
    border-right: 0;
    left: initial;
    right: -30px;
`;

const randomizeArray = (arr) => {
    let _arr = [...arr];
    return arr.reduce((list) => {
        const index = Math.floor(Math.random()*(_arr.length-list.length));
        const item = _arr.splice(index, 1);
        list.push(item[0]);
        return list;
    }, []);
}

class Radio extends React.Component {

    playing = false;
    trackIndex = 0;
    playerRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            trackIndex: 0,
            nowPlaying: undefined,
            tracks: randomizeArray(tracksData.tracks)
        }
    }

    setNextTrack(dir = 1) {
        let newIndex = this.state.trackIndex + dir;
        if (newIndex >= this.state.tracks.length) newIndex = 0;
        if (newIndex < 0) newIndex = this.state.tracks.length - 1;

        this.setState({ trackIndex: newIndex });
        this.playerRef.current.src = this.state.tracks[newIndex].url;
        this.setState({ nowPlaying: this.state.tracks[newIndex].name });
        this.playerRef.current.play();
    }

    togglePlay() {
        const method = this.state.playing ? 'pause' : 'play';
        this.playerRef.current[method]();
        this.setState({ playing: method === 'play'});
    }

    changeTrack(e, dir) {
        e.stopPropagation();
        this.setNextTrack(dir);
    }

    componentDidMount() {
        this.playerRef.current.src = this.state.tracks[this.state.trackIndex].url;
        this.setState({ nowPlaying: this.state.tracks[this.state.trackIndex].name });
        this.playerRef.current.onplaying = () => this.setState({ playing: true });
        this.playerRef.current.onended = () => {
            this.setNextTrack();
        };
    }

    render() {
        return (
            <div>
                <RadioButton className={this.state.playing ? 'playing' : 'not-playing'}>
                    <img src="/images/radio-button.png" alt="Toggle CODA radio" onClick={() => this.togglePlay()}/>
                </RadioButton>
                <a onClick={() => this.togglePlay()}>
                    <NowPlaying playing={this.state.playing}>
                        <Prev onClick={(e) => this.changeTrack(e, -1)} />
                        <img src="/images/radio-button.png" alt="Toggle CODA radio" onClick={() => this.togglePlay()}/>
                        <span ><strong>Now playing:</strong> {this.state.nowPlaying}</span>
                        <Next onClick={(e) => this.changeTrack(e, 1)} />
                    </NowPlaying>
                </a>
                <Player playerRef={this.playerRef}></Player>
            </div>
        )
    }
}

export default Radio;
